import React, { useState, useEffect } from 'react';

const WWDC: React.FC = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const targetDate = new Date('June 10, 2024 00:00:00').getTime();
      const now = new Date().getTime();
      const timeDifference = targetDate - now;

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeRemaining({
          days,
          hours,
          minutes,
          seconds
        });
      }
    };

    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const delayTransition = setTimeout(() => {
      setShowCountdown(true);
    }, 2000); // Adjust delay time as needed

    return () => clearTimeout(delayTransition);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center h-screen p-10">
      <div className={`mb-8 transition-opacity duration-500 ${videoLoaded ? 'opacity-100' : 'opacity-0'} w-4/6 md:w-3/6 lg:w-2/6`}>
        <video
          className="max-w-full"
          loop
          autoPlay
          muted
          playsInline
          onLoadedData={() => setVideoLoaded(true)}
        >
          <source src="https://developer.apple.com/wwdc24/images/motion/mo-large_2x.mp4" />
        </video>
      </div>
      <div className={`transition-opacity duration-500 ${videoLoaded ? 'opacity-100' : 'opacity-0'}`}>
        {showCountdown ? (
          <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
            {timeRemaining.days > 0 && (
              `${timeRemaining.days}d ${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`
            )}
            {timeRemaining.days === 0 && (
              `${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`
            )}
          </p>
        ) : (
          <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">June 10, 2024</p>
        )}
      </div>
    </div>
  );
};

export default WWDC;
